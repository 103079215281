<template>
  <modal
    :width="1250"
    :adaptive="true"
    class="newUserModal CEModal modal-scroll-bar"
    name="newUserModal"
  >
    <h2 class="text-center mb-10">
      New User
    </h2>
    <div class="d-flex flex-wrap mb-10">
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.first_name.$error }"
      >
        <label class="label reseller-info__label"
          >{{ $t('First name') }} *</label
        >
        <input
          class="input"
          type="text"
          v-model="newUser_data.first_name"
          @input="$v.newUser_data.first_name.$touch"
        />
        <div
          class="color-red1 fs12 valide-error-msg"
          v-if="!$v.newUser_data.first_name.required"
        >
          {{ $t('This is a required field.') }}
        </div>
      </div>
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.last_name.$error }"
      >
        <label class="label reseller-info__label"
          >{{ $t('Last Name') }} *</label
        >
        <input
          class="input"
          type="text"
          v-model="newUser_data.last_name"
          @input="$v.newUser_data.last_name.$touch"
        />
        <div
          class="color-red1 fs12 valide-error-msg"
          v-if="!$v.newUser_data.last_name.required"
        >
          {{ $t('This is a required field.') }}
        </div>
      </div>
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.israeli_id.$error }"
      >
        <label class="label reseller-info__label">{{
          $t('ID')
        }}</label>
        <input
          class="input"
          type="text"
          v-model="newUser_data.israeli_id"
          @input="$v.newUser_data.israeli_id.$touch"
        />
        <div
          class="color-red1 fs14 valide-error-msg"
          v-if="!$v.newUser_data.israeli_id.sameAsData"
        >
          {{ $t('Invalid value') }}
        </div>
      </div>
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.phone_number.$error }"
      >
        <label class="label reseller-info__label"
          >{{ $t('Mobile Phone') }} *</label
        >
        <input
          class="input"
          type="text"
          v-model="newUser_data.phone_number"
          @input="$v.newUser_data.phone_number.$touch"
          @keypress="isNumber($event)"
        />
        <div
          class="color-red1 fs12 valide-error-msg"
          v-if="!$v.newUser_data.phone_number.required"
        >
          {{ $t('This is a required field.') }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap mb-10">
      <div
        class="px-5 w-50 validation-form-group"
      >
        <label class="label reseller-info__label">{{
          $t('Address')
        }}</label>
        <vue-google-autocomplete
          id="map-2"
          classname="input"
          placeholder="הזן כתובת מלאה"
          v-on:placechanged="getAddressDataUser"
          :country="['il']"
          v-model="googleAddressInput"
        >
        </vue-google-autocomplete>
      </div>
      <div class="px-5 w-25">
        <label class="label reseller-info__label">{{
          $t('E-mail')
        }}</label>
        <input
          class="input"
          type="email"
          placeholder="email@example.com"
          v-model="newUser_data.account_mail"
        />
      </div>
      <div class="px-5 w-25">
        <label class="label reseller-info__label"
          >{{ $t('Permissions') }} *</label
        >
        <vue-select
          :options="permissions.options"
          class="input cus-select"
          v-model="permissions.selected"
        ></vue-select>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.username.$error }"
      >
        <label class="label reseller-info__label"
          >{{ $t('Username') }} *</label
        >
        <input
          class="input"
          type="text"
          v-model="newUser_data.username"
          @input="$v.newUser_data.username.$touch"
        />
        <div
          class="color-red1 fs12 valide-error-msg"
          v-if="!$v.newUser_data.username.required"
        >
          {{ $t('This is a required field.') }}
        </div>
      </div>
      <div
        class="px-5 w-25 validation-form-group"
        :class="{ 'form-group--error': $v.newUser_data.password.$error }"
      >
        <label class="label reseller-info__label"
          >{{ $t('Password') }} *</label
        >
        <input
          class="input"
          type="text"
          v-model="newUser_data.password"
          @input="$v.newUser_data.password.$touch"
        />
        <div
          class="color-red1 fs12 valide-error-msg"
          v-if="!$v.newUser_data.password.required"
        >
          {{ $t('This is a required field.') }}
        </div>
      </div>
      <fragment v-if="(isDistributorAdmin || isPtAdmin) && permissions.selected.code === DISTRIBUTOR_AGENT_ROLE_ID">
        <div
          class="px-5 w-25 validation-form-group"
        >
          <label class="label reseller-info__label"
            >{{ $t('Daily OBLIGO') }}</label
          >
          <input
            class="input"
            type="number"
            min="0"
            v-model="newUser_data.daily_obligo"
          />
        </div>
        <div
          class="px-5 w-25 validation-form-group"
        >
          <label class="label reseller-info__label"
            >{{ $t('Disable FRAME option') }}</label
          >
          <v-checkbox
            v-model="newUser_data.disable_frame_option"
          ></v-checkbox>
        </div>
      </fragment>
    </div>
    <div class="d-flex mt-10 mb-10 bottom-action">
        <button class="primary-btn mx-5" @click="save()">
          {{ $t('Save') }}
        </button>
        <button class="primary-btn mx-5 bg-red1" @click="closeModal()">
          {{ $t('Cancel') }}
        </button>
      </div>
  </modal>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { DISTRIBUTOR_ADMIN_ROLE_ID, DISTRIBUTOR_AGENT_ROLE_ID } from '@/common/constants';
import { validationMixin } from 'vuelidate';
import { required, email, sameAsData } from "vuelidate/lib/validators";
import user_mixin from '@/utility/user_mixin';

export default {
  name: 'NewUserModal',
  mixins: [validationMixin, user_mixin],
  validations: {
    newUser_data: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      israeli_id: {
        sameAsData: function () {
          let id = this.newUser_data.israeli_id;
          id = String(id).trim();
          if (id.length == 0) {
            return true;
          } else if (id.length > 9 || id.length < 5) {
            return false;
          }

          // Pad string with zeros up to 9 digits
          id = id.length < 9 ? ('00000000' + id).slice(-9) : id;

          return (
            Array.from(id, Number).reduce((counter, digit, i) => {
              const step = digit * ((i % 2) + 1);
              return counter + (step > 9 ? step - 9 : step);
            }) %
              10 ===
            0
          );
        },
      },
      phone_number: {
        required,
      },
      account_mail: {
        email,
      },
      password: {
        required,
      },
      username: {
        required,
      },
    },
  },
  props: {
    business_id: {}
  },
  data() {
    return {
      newUser_data: {
        first_name: '',
        last_name: '',
        israeli_id: '',
        phone_number: '',
        account_mail: '',
        password: '',
        username: '',
        city: '',
        street: '',
        country: '',
      },
      permissions: {
        options: [],
        selected: '',
      },
      googleAddressInput: '',
    };
  },
  async created() {
    this.DISTRIBUTOR_ADMIN_ROLE_ID = DISTRIBUTOR_ADMIN_ROLE_ID;
    this.DISTRIBUTOR_AGENT_ROLE_ID = DISTRIBUTOR_AGENT_ROLE_ID;
    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.rolesList.forEach(el => {
        this.permissions.options.push({
          label: el.name,
          code: el.id
        })
      });
      this.permissions.selected = this.permissions.options.find(el => {
        return el.label == "משווק";
      });
    }
  },
  computed: {
    ...mapState({
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
    }),
  },
  methods: {
    ...mapActions('shaniv_management', {
      createUser: 'createUser',
      verifyFields: 'verifyFields',
    }),
    ...mapActions('alertTwo', {
      errorAlert: 'error',
    }),
    ...mapActions('seller_tourism_percentage', {
      getRoles: 'getRoles',
    }),
    getAddressDataUser(address) {
      this.newUser_data.country = address.country;
      this.newUser_data.city = address.locality;
      this.newUser_data.street = address.route;
    },
    resetModal() {
      let originData = {
        first_name: '',
        last_name: '',
        israeli_id: '',
        phone_number: '',
        account_mail: '',
        password: '',
        username: '',
        city: '',
        street: '',
        country: '',
        daily_obligo: '',
        disable_frame_option: false,
      }
      this.newUser_data = originData;
       

    },
    closeModal() {
      this.$modal.hide('newUserModal');
      this.resetModal();
      this.$v.$reset();
    },
    save() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }

      let userNameVerifyData = {
        field: 'login_name',
        value: this.newUser_data.username
      }
      let idVerifyData = {
        field: 'israeli_id',
        value: this.newUser_data.israeli_id
      }

      this.verifyFields(userNameVerifyData).then(verifyUname => {
        if (!verifyUname) {
          this.verifyFields(idVerifyData).then(async verifyId => {
            if (!verifyId) {
              let newUser = this.newUser_data;
              newUser.role_id = this.permissions.selected.code;
              newUser.business_id = this.business_id;
              await this.createUser(newUser);
              this.$emit("userCreated");
              this.closeModal();
            } else {
              this.errorAlert( "Id is dulplicated" );
              this.closeModal();
            }
          })
        } else {
          this.errorAlert( "User name is dulplicated" );
          this.closeModal();
        }
      });
    }
  },
};
</script>

<style lang="scss">
  .newUserModal {
    .vm--modal {
      padding: 30px;
    }
  }
</style>

<style lang="scss" scoped>
  .bottom-action {
    .primary-btn {
      width: 100%;
      max-width: 200px;
    }
  }
  @media screen and (max-width: 900px) {
    .w-25 {
      width: 50% !important;
    }
  }
  @media screen and (max-width: 635px) {
    .w-25,
    .w-50 {
      width: 100% !important;
    }
  }
</style>